<template>
  <v-container style="max-width: 1000px">
    <header>
      <v-layout full-width align-center :column="$vuetify.breakpoint.xs">
        <v-flex>
          <img :src="logoForCompany" width="200" />
        </v-flex>
        <v-spacer grow />
      </v-layout>

      <br />
      <br />

      <h1 class="text-xs-center">Invoice</h1>
    </header>

    <div class="provider-quote provider-quote__body">
      <div class="row contact-information pb-4">
        <div class="quarter">
          <h1 class="mb-1" style="margin-top: -5px">Billing Contact</h1>
          <br />

          <div class="row padded">
            <div>
              <b>
                {{ invoice.customer.firstName }} {{ invoice.customer.lastName }}
              </b>
              <v-flex v-if="customerAccountName" xs12>
                <p style="margin-bottom: 2px">{{ customerAccountName }}</p>
              </v-flex>
              <p>
                <a target="_blank" :href="`mailto:${invoice.customer.email}`">
                  {{ invoice.customer.email }}
                </a>
              </p>
              <p>
                <a :href="`tel:${invoice.customer.phone}`">
                  {{ phoneFormatFilter(invoice.customer.phone) }}
                </a>
              </p>
            </div>
          </div>
        </div>

        <div class="border-left padded-left">
          <h1 class="mb-1" style="margin-top: -5px">Company Information</h1>
          <br />
          <b>{{ invoice.company.name }}</b>
          <p>
            <a target="_blank" :href="`mailto:${invoice.company.email}`">
              {{ invoice.company.email }}
            </a>
          </p>
          <p>
            <a :href="`tel:${invoice.company.phone}`">
              {{ phoneFormatFilter(invoice.company.phone) }}
            </a>
          </p>
        </div>
      </div>
      <v-divider />

      <div class="page pt-4 pb-5">
        <div class="row">
          <div>
            <h1 class="mb-1">Invoice Summary</h1>
            <br />

            <table>
              <tbody>
                <tr>
                  <td style="width: 25%" class="bold">
                    <p style="font-weight: 700">Quote ID:</p>
                  </td>
                  <td style="width: 25%" class="bold">
                    <p style="font-weight: 700">Reservation ID:</p>
                  </td>
                  <td style="width: 25%" class="align-right bold">
                    <p style="font-weight: 700">Total Charges:</p>
                  </td>
                  <td style="width: 25%" class="align-right bold">
                    <p style="font-weight: 700">Remaining Balances:</p>
                  </td>
                </tr>
                <tr
                  v-for="(reservation, index) in invoice.invoiceReservations"
                  :key="`res-row-${index}`"
                >
                  <td style="width: 25%; border-width: 0 1px">
                    <p
                      :style="[
                        index !== 0 &&
                        invoice.invoiceReservations[index - 1].trip.quoteId !==
                          reservation.trip.quoteId
                          ? { 'margin-top': '12px' }
                          : {},
                      ]"
                    >
                      {{
                        index === 0 ||
                        invoice.invoiceReservations[index - 1].trip.quoteId !==
                          reservation.trip.quoteId
                          ? reservation.trip.quoteId
                          : ''
                      }}
                    </p>
                  </td>
                  <td style="width: 25%; border-width: 0 1px">
                    <p
                      :style="[
                        index !== 0 &&
                        invoice.invoiceReservations[index - 1].trip.quoteId !==
                          reservation.trip.quoteId
                          ? { 'margin-top': '12px' }
                          : {},
                      ]"
                    >
                      {{ reservation.managedId }}
                    </p>
                  </td>
                  <td
                    style="width: 25%; border-width: 0 1px"
                    class="align-right"
                  >
                    <p
                      :style="[
                        index !== 0 &&
                        invoice.invoiceReservations[index - 1].trip.quoteId !==
                          reservation.trip.quoteId
                          ? { 'margin-top': '12px' }
                          : {},
                      ]"
                    >
                      {{ currencyFilter(reservation.totalCharges) }}
                    </p>
                  </td>
                  <td
                    style="width: 25%; border-width: 0 1px"
                    class="align-right"
                  >
                    <p
                      :style="[
                        index !== 0 &&
                        invoice.invoiceReservations[index - 1].trip.quoteId !==
                          reservation.trip.quoteId
                          ? { 'margin-top': '12px' }
                          : {},
                      ]"
                    >
                      {{ currencyFilter(reservation.balance) }}
                    </p>
                  </td>
                </tr>

                <tr>
                  <td style="width: 25%"></td>
                  <td style="width: 25%"></td>
                  <td style="width: 25%" class="align-right bold">
                    <p>{{ currencyFilter(invoice.totalCharges) }}</p>
                  </td>
                  <td style="width: 25%" class="align-right bold">
                    <p>{{ currencyFilter(invoice.totalBalances) }}</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <v-divider />

      <div class="page pt-4 pb-5">
        <h1 class="mb-1" style="text-align: center">
          <strong>Terms and Conditions</strong>
        </h1>
        <br />
        <div class="terms" style="padding: 24px">
          {{ invoice.checkoutPage.terms }}
        </div>
      </div>
      <v-divider />

      <v-layout
        v-if="invoice.totalBalances !== 0"
        justify-start
        align-start
        pt-3
        pb-5
      >
        <div class="page">
          <h1 class="mb-1">
            Payment Information

            <CRIcon
              class="ml-2"
              color="primary"
              :view-box="`0 0 32 24`"
              :width="20"
              :height="20"
            >
              credit_card
            </CRIcon>
          </h1>
          <CollectPaymentNew
            :id="`invoice-payments`"
            v-bind="$attrs"
            :rows="invoice.invoiceReservations"
            :balance="invoice.totalBalances"
            :invoice="invoice"
            @submit="handleSubmit"
          />
        </div>
      </v-layout>
      <v-divider v-if="invoice.totalBalances !== 0" />

      <div
        v-for="reservation in invoice.invoiceReservations"
        :key="reservation.managedId"
        class="page pt-2"
      >
        <div class="provider-quote__card">
          <v-layout
            align-center
            row
            class="provider-quote__card__header px-2 py-2"
          >
            <h1 style="font-weight: 700">
              Reservation ID: {{ reservation.managedId }}
            </h1>
            <h1>Quote ID: {{ reservation.trip.quoteId }}</h1>
          </v-layout>
          <div class="row align-center">
            <div class="provider-quote__cell">
              <p>Trip Type</p>
              <p>
                {{ reservation.trip.tripType.label }}
              </p>
            </div>
            <div class="provider-quote__cell">
              <p>Passengers</p>
              <p>
                {{ reservation.trip.passengerCount }}
              </p>
            </div>
            <div class="provider-quote__cell">
              <p>Drivers</p>
              <p>
                {{ reservation.trip.requiredDrivers }}
              </p>
            </div>
            <div class="provider-quote__cell">
              <p>Vehicle</p>
              <p
                v-for="vehicle in reservation.trip.requiredVehicles"
                :key="vehicle.vehicleId"
              >
                {{ vehicle.quantity }}
                {{ vehicle.vehicleType.label }}
              </p>
            </div>
            <div class="provider-quote__cell">
              <p>Payment Type</p>
              <p>
                {{ reservation.trip.paymentType.label }}
              </p>
            </div>
            <div class="provider-quote__cell">
              <p>Pickup Date</p>
              <p>
                {{ pickupDateStringForTrip(reservation.trip) }}
              </p>
            </div>
          </div>

          <div class="provider-quote__card-section" style="padding-bottom: 5px">
            <div class="provider-quote__itinerary mb-4">
              <h3>Itinerary</h3>
            </div>
            <div
              v-for="(stop, stopIndex) in reservation.trip.stops"
              :key="stop.stopId"
              class="row stop-row py-2"
            >
              <div class="stop-number">
                {{ stopIndex + 1 }}
              </div>
              <div class="stop-info row align-start">
                <div class="stop-info-address">
                  <h4>Address</h4>
                  <p v-if="stop.address.title">
                    {{ stop.address.title }}
                  </p>
                  <p>
                    {{ stop.address.addressName }}
                  </p>
                  <template
                    v-if="
                      (stop.stopNotes &&
                        stop.stopNotes[0] &&
                        (stop.stopNotes[0].html || stop.stopNotes[0].note)) ||
                      stop.notes
                    "
                  >
                    <p class="quote__label" style="padding-top: 7px">Notes:</p>
                    <p
                      v-if="stop.stopNotes && stop.stopNotes[0]"
                      class="quote__label"
                      v-html="stop.stopNotes[0].html || stop.stopNotes[0].note"
                    />
                    <p v-else class="quote__label">
                      {{ stop.notes }}
                    </p>
                  </template>
                </div>

                <div class="stop-info-dropoff">
                  <template v-if="stop.dropoffDatetime">
                    <h4>Dropoff</h4>
                    <p>
                      {{
                        dateAsString(
                          stop.dropoffDatetime,
                          stop.address.timeZone
                        )
                      }}
                    </p>
                  </template>
                </div>

                <div class="stop-info-pickup">
                  <template v-if="stop.pickupDatetime">
                    <h4>Pickup</h4>
                    <p>
                      {{
                        dateAsString(stop.pickupDatetime, stop.address.timeZone)
                      }}
                    </p>
                  </template>
                </div>
              </div>
            </div>
          </div>

          <div class="provider-quote__card-section">
            <h3>Trip Notes</h3>
            <div
              style="white-space: pre-wrap; line-height: 1.5"
              v-html="
                previousTripNotes.customerNotes || reservation.trip.description
              "
            />
          </div>

          <div
            v-if="reservation.trip.tripContact"
            class="provider-quote__card-section"
          >
            <h3>Trip Contact</h3>
            <p class="row">
              <span>
                {{ reservation.trip.tripContact.firstName }}
                {{ reservation.trip.tripContact.lastName }}
              </span>
              <a
                :href="`tel:${reservation.trip.tripContact.phone}`"
                style="margin-left: 18px"
              >
                {{ phoneFormatFilter(reservation.trip.tripContact.phone) }}
              </a>
              <a
                target="_blank"
                :href="`mailto:${reservation.trip.tripContact.email}`"
                style="margin-left: 18px"
              >
                {{ reservation.trip.tripContact.email }}
              </a>
            </p>
          </div>
        </div>

        <div class="trip-rates-charges-container">
          <div class="trip-rates">
            <h3 v-if="reservation.trip.tripRates.length">Overage Rates</h3>
            <p
              v-for="rate in reservation.trip.tripRates"
              :key="rate.rateId"
              class="rate"
            >
              <span>{{ rate.rateType.label }}</span>
              <span>{{ currencyFilter(rate.amount) }}</span>
            </p>
          </div>

          <div class="trip-charges">
            <h3>Trip Charges</h3>
            <div
              v-for="invoiceCharge in reservation.invoiceCharges"
              :key="invoiceCharge.chargeId"
            >
              <p class="charge">
                <span>{{ invoiceCharge.chargeType.label }}</span>
                <span>{{ currencyFilter(invoiceCharge.amount) }}</span>
              </p>
              <small style="color: gray">
                {{ invoiceCharge.customerNotes }}
              </small>
            </div>
            <p class="charge">
              <span v-if="reservation.trip.processingFeePercentage">
                Processing Fee ({{ reservation.trip.processingFeePercentage }}%)
              </span>
              <span v-if="reservation.trip.processingFeePercentage">
                {{ currencyFilter(reservation.trip.processingFeeCharge.value) }}
              </span>
            </p>
            <p class="charge total trip-total">
              <span>Trip Total</span>
              <span>{{ currencyFilter(reservation.totalCharges) }}</span>
            </p>
            <p
              v-if="reservation.trip.paymentType.key === 'down_payment'"
              class="charge total trip-deposit"
            >
              <span>
                Amount Due
                {{
                  dateAsShortString(
                    reservation.trip.dueDate,
                    reservation.trip.stops[0].address.timeZone
                  )
                }}
              </span>
              <span>
                {{
                  currencyFilter(
                    getAmountDueLaterForInvoiceReservation(reservation)
                  )
                }}
              </span>
            </p>
          </div>
        </div>

        <div v-if="reservation.invoiceEntries.length" class="trip-payments">
          <h3>Payments Received</h3>

          <div
            v-for="invoiceEntry in reservation.invoiceEntries"
            :key="invoiceEntry.transactionId"
            class="payment"
          >
            <div class="payment-date">
              {{
                dateAsShortString(
                  invoiceEntry.createdOn,
                  reservation.trip.stops[0].address.timeZone
                )
              }}
            </div>
            <div class="payment-description">
              {{ invoiceEntry.description }}
            </div>
            <div class="payment-amount">
              {{ currencyFilter(invoiceEntry.amount) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import { mask } from 'vue-the-mask'

import { DateTime } from 'luxon'
import { currencyFilter, pluralize } from '@/utils/string'
import { phoneFormatFilter } from '@/utils/phone'
import CollectPaymentNew from '@/components/CollectPaymentNew.vue'

const CUSTOMER_NOTE_TYPE = 2

export default {
  components: {
    CollectPaymentNew,
  },
  directives: {
    mask,
  },
  provide: {
    isInvoice: true,
  },
  props: {
    paymentGateway: { type: Object, default: () => undefined },
    invoice: {
      type: Object,
      default: () => ({
        company: {
          address: {},
        },
        customer: {},
        trips: [],
        notes: [],
      }),
    },
    terms: {
      type: String,
      default: '',
    },
    isSubmitting: {
      type: Boolean,
    },
  },
  data() {
    return {
      formIsValid: false,
      billingAddressFormIsValid: false,
      activePaymentMethod: '',
      stopNotes: this.invoice.invoiceReservations.map((invoiceReservation) => {
        let tripStops = [...invoiceReservation.trip.stops]
        tripStops = tripStops.map((stop) => ({ active: false }))
        return { stops: tripStops }
      }),
    }
  },
  computed: {
    logoForCompany() {
      if (this.invoice?.company) {
        return `${this.invoice.company.darkLogoUrl}`
      }
      return ''
    },
    customerAccountName() {
      return this.invoice?.customer?.customerAccount?.name
    },
    previousTripNotes() {
      let notes = this.invoice?.invoiceReservations[0].trip?.tripNotes
      if (notes) {
        let customerNotes = notes.find((note) => {
          return note.noteType === CUSTOMER_NOTE_TYPE
        })

        let previousNotes = {}
        previousNotes.customerNotes =
          customerNotes?.html ||
          customerNotes?.note ||
          this.invoice.invoiceReservations[0].trip.description

        return previousNotes
      }

      return {}
    },
    total() {
      return this.reservation?.trips.reduce(
        (total, trip) => total + parseFloat(trip.total),
        0
      )
    },
    amountDueToday() {
      return this.reservation?.trips.reduce((total, trip) => {
        return total + parseFloat(this.getAmountDueTodayForTrip(trip))
      }, 0)
    },
    amountDueLater() {
      return this.reservation?.trips.reduce((total, trip) => {
        return total + this.getAmountDueLaterForTrip(trip)
      }, 0)
    },
  },
  methods: {
    currencyFilter,
    phoneFormatFilter,
    pluralize,
    itineraryDate(datetime, zone) {
      return DateTime.fromISO(datetime, { zone }).toLocaleString(
        DateTime.DATE_SHORT
      )
    },
    itineraryTime(datetime, zone) {
      return DateTime.fromISO(datetime, { zone }).toLocaleString(
        DateTime.TIME_SIMPLE
      )
    },
    paymentMethodAllowed(type) {
      return this.invoice?.invoiceReservations[0].trip?.paymentMethods?.find(
        (method) => {
          return method.isAllowed && method.paymentMethodType?.key === type
        }
      )
    },
    dateAsString(datetime, zone) {
      return DateTime.fromISO(datetime, { zone }).toLocaleString(
        DateTime.DATETIME_SHORT
      )
    },
    dateAsShortString(datetime, zone) {
      return DateTime.fromISO(datetime, { zone }).toLocaleString()
    },
    pickupDateStringForTrip(trip) {
      return this.dateAsShortString(
        trip.stops[0].pickupDatetime,
        trip.stops[0].address.timeZone
      )
    },
    toggleStopNotes(tripIndex, stopIndex) {
      this.stopNotes[tripIndex].stops[stopIndex].active = !this.stopNotes[
        tripIndex
      ].stops[stopIndex].active
    },
    getChargeTotalForInvoiceReservation(invoiceReservation) {
      let total =
        invoiceReservation.invoiceCharges.reduce?.((total, charge) => {
          return total + charge.amount
        }, 0) || 0

      return total
    },
    getDepositAmountForInvoiceReservation(invoiceReservation) {
      return Number(
        Math.round(
          this.getChargeTotalForInvoiceReservation(invoiceReservation) *
            (parseFloat(invoiceReservation.trip.depositPercentage) / 100.0) *
            100.0
        ) / 100.0
      ).toFixed(2)
    },
    getAmountDueTodayForInvoiceReservation(invoiceReservation) {
      if (invoiceReservation.trip.paymentType.key === 'full_payment') {
        return parseFloat(
          this.getChargeTotalForInvoiceReservation(invoiceReservation)
        )
      } else if (invoiceReservation.trip.paymentType.key === 'down_payment') {
        return this.getDepositAmountForInvoiceReservation(invoiceReservation)
      } else {
        return 0
      }
    },
    getAmountDueLaterForInvoiceReservation(invoiceReservation) {
      if (invoiceReservation.trip.paymentType.key === 'full_payment') {
        return 0
      } else if (invoiceReservation.trip.paymentType.key === 'down_payment') {
        return (
          parseFloat(
            this.getChargeTotalForInvoiceReservation(invoiceReservation)
          ) - this.getDepositAmountForInvoiceReservation(invoiceReservation)
        )
      } else {
        return parseFloat(
          this.getChargeTotalForInvoiceReservation(invoiceReservation)
        )
      }
    },
    handleSubmit() {
      this.$emit('submit')
    },
  },
}
</script>

<style lang="scss" scoped>
$gray-light-text: #73818a;
$blue-text: #259cf7;
$blue-bg: #edf4fa;
$border: #edf4fa;
$gray-text: #3e4351;
$gray-lighter-text: #dbdbdb;
$gray-bg: #3e4351;
$gray-light-bg: #f9f9f9;
$green-bg: #7ccc6c;

.row {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 9px;

  &.contact-information {
    justify-content: left;
  }

  &.padded > .half:not(:first-child) {
    padding-left: 20px;
    width: calc(50% - 20px);
  }
}

.v-label {
  font-size: 14px !important;
}
.signature-container {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  page-break-before: always;

  .field {
    width: 200px;
    height: 9px;
    padding-bottom: 3px;
  }

  .column-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .half-row {
    margin-top: 25px;
    display: flex;
    width: 50%;
    justify-content: left;
    margin-bottom: 9px;
  }
}

.align-bottom {
  align-items: flex-end;
}

.quarter {
  flex-basis: 25%;
  width: 25%;
}

.one-third {
  flex-basis: 33.33%;
  width: 33.33%;
}

.half {
  flex-basis: 50%;
  width: 50%;
}

.two-thirds {
  flex-basis: 66.67%;
  width: 66.67%;
  &.padded-right {
    flex-basis: 62%;
    width: 62%;
  }
}

.border-left {
  border-left: 1px solid $border;
}

.padded-left {
  padding-left: 40px;
}

.align-right {
  text-align: right;
}

.bold {
  font-weight: 700;
}

.provider-quote {
  color: $gray-text;
  font-family: 'Lato';

  &__header,
  &__body,
  &__footer {
    margin: 0 20px;
  }

  a {
    text-decoration: none;
    color: $blue-text;
  }

  p {
    margin-top: 2px;
    margin-bottom: 2px;
    font-size: 14px;
  }

  h1 {
    font-size: 20px;
    margin: 0;
  }

  h2 {
    font-size: 14px;
    margin-bottom: 9px;
    margin-top: 0;
  }

  h3 {
    font-size: 18px;
    margin-top: 0;
    margin-bottom: 9px;
  }

  h4,
  h5 {
    font-size: 14px;
    line-height: 1.2;
  }

  h4 {
    color: black;
    font-weight: 400;
  }

  h5 {
    color: $gray-light-text;
    font-weight: 400;
  }

  pre {
    font-size: 9px;
  }

  &__header {
    color: $gray-text;
    font-family: 'Lato';
    font-size: 9px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0px 20px 0px;
    border-bottom: 1px solid $blue-bg;

    h1 {
      font-size: 20px;
      margin-right: 40px;
    }
  }

  &__quote-number-container {
    text-align: center;
    border: 1px solid $gray-bg;
    border-radius: 5px;
    padding: 5px 15px;

    &--emphasized {
      font-weight: bold;
      font-size: 14px;
    }
  }

  &__submit {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  &__body {
    page-break-after: always;

    .page {
      page-break-after: always;
    }
  }

  &__contact {
    border-right: 1px solid $blue-bg;
  }

  &__card {
    border: 1px solid $blue-bg;
    margin-top: 20px;

    &__header {
      background: $blue-light;
      h1 {
        font-size: 20px;
      }
    }

    &.quote-details {
      margin-top: 0;
      margin-bottom: 12px;
      padding: 8px;
      p {
        font-size: 12px;
      }
    }

    > .row {
      margin: 0;
    }

    h2 {
      background-color: $blue-bg;
      padding: 5px 10px;
      margin: 0;
    }
  }

  &__cell {
    width: 16.6%;
    text-align: center;
    border-bottom: 1px solid $blue-bg;
    padding-top: 14px;
    padding-bottom: 14px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &:not(:first-child) {
      border-left: 1px solid $blue-bg;
    }
  }

  &__first-cell {
    width: 20%;
    text-align: center;
    border-bottom: 1px solid $blue-bg;
    padding-top: 14px;
    padding-bottom: 14px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &:not(:first-child) {
      border-left: 1px solid $blue-bg;
    }
  }

  &__second-cell {
    width: 33.3%;
    text-align: center;
    border-bottom: 1px solid $blue-bg;
    padding-top: 14px;
    padding-bottom: 14px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &:not(:first-child) {
      border-left: 1px solid $blue-bg;
    }
  }

  &__card-section {
    padding: 16px;
  }

  &__itinerary {
    background-color: $blue-bg;
    padding: 5px 10px;

    h3 {
      font-size: 18px;
      font-weight: 700;
      margin: 0;
    }
  }

  .stop-row {
    margin: 0;

    .stop-number {
      width: 6%;
      line-height: 1.2;
      text-align: center;
      font-size: 28px;
      font-weight: 700;
      color: $gray-lighter-text;
    }

    .stop-info {
      margin: 0;
      width: 90%;
      border-bottom: 1px solid $blue-bg;
    }

    &:last-child .stop-info {
      border-bottom: none;
    }

    .stop-info-address {
      width: 46%;

      h4 {
        font-size: 14px;
        line-height: 1.2;
      }
    }

    .stop-info-dropoff {
      width: 21%;
    }

    .stop-info-pickup {
      width: 21%;
    }
  }

  .trip-rates-charges-container {
    display: flex;
    width: 100%;
  }

  .trip-rates {
    font-size: 10px;
    flex-basis: 50%;
    border: 1px solid $blue-bg;
    border-top: none;
    border-right: none;
    padding: 16px;

    .rate {
      padding: 4px;
      padding-left: 0;

      > span:last-child {
        float: right;
      }
    }
  }

  .trip-charges {
    font-size: 10px;
    flex-basis: 50%;
    border: 1px solid $blue-bg;
    border-top: none;
    padding: 16px;

    h2 {
      padding: 6px;
    }

    .charge {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      right: 4px;
      padding: 4px;
      border-radius: 3px;

      > span:only-child {
        margin-left: auto;
      }
    }

    .total {
      padding: 5px;
      margin-top: 5px;
    }

    .trip-total {
      color: white;
      background: $gray-bg;
    }

    .trip-deposit {
      padding: 4px;
      border: 1px solid $gray-bg;
    }

    .trip-due-today {
      color: white;
      background: $green-bg;
    }
  }

  .trip-payments {
    font-size: 10px;
    border: 1px solid $blue-bg;
    border-top: none;
    padding: 16px;

    .payment {
      width: 100%;
      padding: 4px;
      padding-left: 0;
      display: flex;
      justify-content: space-between;

      > span:last-child {
        float: right;
      }
    }
  }

  .trip-payment-summaries {
    border-radius: 5px;
    background-color: $gray-bg;
    padding: 9px;
    color: white;
    margin-bottom: 16px;

    .summary {
      &:not(:first-child) {
        border-top: 1px solid $gray-light-bg;
        margin-top: 18px;
        padding-top: 6px;
      }

      > p {
        padding: 5px 6px;

        > span:last-child {
          float: right;
        }
      }

      .summary-due {
        color: $gray-text;
        background: white;
        border-radius: 3px;
        margin-bottom: 5px;
      }

      .summary-due-today {
        font-weight: 700;
      }
    }
  }

  .grand-total {
    margin-top: 8px;
    border-radius: 5px;
    padding: 12px;
    font-weight: 700;

    > span:last-child {
      float: right;
    }
  }

  .quote-total {
    font-size: 10px;
    background-color: $blue-bg;
  }

  .quote-total-due-today {
    color: white;
    font-size: 11px;
    background-color: $green-bg;
  }

  .terms {
    width: 100%;
    line-height: 1.5;
    font-size: 14px;
    white-space: pre-wrap;
    border-radius: 12px;
    border: solid 1px $blue-light;
    max-height: 240px;
    overflow: scroll;
  }

  .form {
    position: relative;
    background-color: $gray-light-bg;
    padding: 9px;
    margin-bottom: 7px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;

    .checkbox-container {
      position: absolute;
      background: $gray-light-bg;
      height: 26px;
      width: 16px;
      left: -16px;
      top: 0;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;

      .checkbox {
        position: absolute;
        top: 8px;
        right: 0;
        padding: 4px;
        width: 1px;
        height: 1px;
        border: solid 1px $blue-text;
        border-radius: 2px;
      }
    }

    p {
      font-size: 8px;
      margin-bottom: 10px;
    }

    .field {
      height: 8px;
      padding-bottom: 2px;
      border-bottom: 1px solid $gray-bg;
    }
  }

  &__footer {
    padding-top: 18px;
    color: $gray-light-text;
    text-align: right;
    font-family: 'Lato';
    font-size: 9px;
  }
}

table {
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
  tbody {
    tr {
      td {
        padding: 4px 8px;
        font-size: 10px;
        border: 1px solid $blue-bg;
      }
    }
  }
}

header,
section {
  padding: 10px;
}

a {
  text-decoration: none;
  color: $primary;
}

h2 {
  border-bottom: none;
}

h4 {
  font-size: 14px;
}

.primary {
  background-color: $primary;
}
</style>
