<template>
  <div>
    <v-snackbar
      v-for="alert in alerts"
      :key="alert.date"
      :color="alert.type === 'error' ? 'error' : 'success'"
      :value="alert.shown"
      :bottom="y === 'bottom'"
      :left="x === 'left'"
      :multi-line="alert.mode === 'multi-line'"
      :right="x === 'right'"
      :timeout="alert.timeout >= 0 ? alert.timeout : timeout"
      :top="y === 'top'"
      :vertical="alert.mode === 'vertical'"
      :auto-height="true"
    >
      {{ alert.message }}

      <v-btn
        v-if="alert.hasAction"
        :id="'app-alert-button-action'"
        icon
        @click="alert.action"
      >
        <v-icon>mdi-cached</v-icon>
      </v-btn>

      <v-btn
        v-if="alert.actionText && alert.actionPath && !alert.actionAdditional"
        :id="alert.actionId || 'app-alert-button-action-undefined'"
        color="primary"
        dark
        @click="$router.push({ path: alert.actionPath })"
      >
        {{ alert.actionText }}
      </v-btn>

      <v-btn
        v-if="alert.undoable"
        color="primary"
        dark
        @click="undoAlertAction(alert)"
      >
        Undo
      </v-btn>

      <v-icon
        id="app-alert-button-close"
        color="white"
        @click="hideAlert(alert)"
      >
        close
      </v-icon>
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  data() {
    return {
      y: 'top',
      x: null,
      timeout: 10000,
    }
  },
  computed: {
    ...mapGetters({ alerts: 'app/alerts' }),
  },
  methods: {
    ...mapActions({
      hideAlert: 'app/hideAlert',
      undoAlertAction: 'app/undoAlertAction',
    }),
    handleButtonAction(action, payload, path) {
      this.$router.push(path)
      this.$store.dispatch(action, payload)
    },
  },
}
</script>

<style lang="scss" scoped>
.v-btn,
.v-icon {
  margin-left: 14px;
}
</style>
