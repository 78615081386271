import { render, staticRenderFns } from "./InvoiceProviderForm.vue?vue&type=template&id=672dd2f9&scoped=true"
import script from "./InvoiceProviderForm.vue?vue&type=script&lang=js"
export * from "./InvoiceProviderForm.vue?vue&type=script&lang=js"
import style0 from "./InvoiceProviderForm.vue?vue&type=style&index=0&id=672dd2f9&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "672dd2f9",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VContainer,VDivider,VFlex,VLayout,VSpacer})
