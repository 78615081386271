
import { envTitlePrefix } from '@/utils/env'
import { Vue, Component } from 'vue-property-decorator'

@Component
export default class EnvironmentIndicator extends Vue {
  envString: string = envTitlePrefix()

  isDragging = false

  position = window.innerWidth / 2
  velocity = 0
  initialMousePosition = 0
  animationFrameId: number | null = null

  startDrag(event: MouseEvent) {
    this.isDragging = true
    this.initialMousePosition = event.clientX
    this.velocity = 0
    this.animationFrameId = null
    document.addEventListener('mousemove', this.onDrag)
    document.addEventListener('mouseup', this.stopDrag)
  }

  onDrag(event: MouseEvent) {
    if (!this.isDragging) return

    const deltaX = event.clientX - this.initialMousePosition
    this.position = Math.max(
      0,
      Math.min(this.position + deltaX, window.innerWidth - this.tagElementTotalWidth)
    )
    this.velocity = deltaX
    this.initialMousePosition = event.clientX
  }

  stopDrag() {
    this.isDragging = false
    document.removeEventListener('mousemove', this.onDrag)
    document.removeEventListener('mouseup', this.stopDrag)
    this.startPhysics()
  }

  startPhysics() {
    const friction = 0.95 // Deceleration factor
    const bounceFactor = -0.8 // Reverse direction on bounce

    const animate = () => {
      if (this.isDragging) return // Stop animation during drag

      this.velocity *= friction
      this.position += this.velocity

      // Check for wall collisions and bounce
      if (this.position <= 0) {
        this.position = 0
        this.velocity *= bounceFactor
      } else if (this.position >= window.innerWidth - this.tagElementTotalWidth) {
        this.position = window.innerWidth - this.tagElementTotalWidth
        this.velocity *= bounceFactor
      }

      // Stop animation if velocity is very small
      if (Math.abs(this.velocity) > 0.1) {
        this.animationFrameId = requestAnimationFrame(animate)
      } else {
        this.animationFrameId = null
      }
    }

    if (!this.animationFrameId) {
      this.animationFrameId = requestAnimationFrame(animate)
    }
  }

  get tagElementTotalWidth(): number {
    const element = this.$refs.tag as HTMLElement
    if (!element) return 0

    const computedStyle = getComputedStyle(element)
    const paddingLeft = parseFloat(computedStyle.paddingLeft)
    const paddingRight = parseFloat(computedStyle.paddingRight)

    return element.offsetWidth + paddingLeft + paddingRight
  }

  beforeDestroy() {
    // Cleanup animation frame and listeners
    if (this.animationFrameId) {
      cancelAnimationFrame(this.animationFrameId)
    }
    document.removeEventListener('mousemove', this.onDrag)
    document.removeEventListener('mouseup', this.stopDrag)
  }
}
