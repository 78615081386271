<template>
  <section class="checkout-base">
    <div
      v-if="invoice.company && invoice.company.name"
      class="checkout-confirmation container"
    >
      <div class="company-image">
        <CharterUpLogo
          v-if="invoice.company.companyId === 2"
          width="280"
          :color="$cr.theme.primary"
        />
        <img
          v-else
          :src="`${invoice.company.darkLogoUrl}`"
          width="280"
          style="display: block; margin: 0 auto"
        />
      </div>
      <br />
      <div class="checkout-confirmation__symbol">
        <CRIcon>done</CRIcon>
      </div>
      <div class="checkout-confirmation__heading">
        Thanks for your payment to {{ invoice.company.name }}!
      </div>
      <div class="checkout-confirmation__details">
        A confirmation email with your payment receipt is on its way now. If you
        have any questions please contact us at
        <a :href="`mailto:${invoice.company.email}`">
          {{ invoice.company.email }}
        </a>
        or
        <a
          :href="`tel:${invoice.company.phone}`"
          style="white-space: nowrap; overflow: hidden"
        >
          {{ phoneFormatFilter(invoice.company.phone) }}
        </a>
        .
      </div>
      <br />
      <br />
      <div style="font-size: 20px">
        <span>
          Powered by &nbsp;&nbsp;
          <img
            data-v-d4e28758=""
            src="@/assets/images/logo-coachrail.svg"
            style="position: relative; width: 120px; top: 2px; left: 2px"
          />
        </span>
      </div>
    </div>
  </section>
</template>

<script>
import { phoneFormatFilter } from '@/utils/phone'
import CharterUpLogo from '@/components/CharterUpLogo.vue'

export default {
  components: {
    CharterUpLogo,
  },
  props: {
    invoice: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    phoneFormatFilter,
  },
}
</script>

<style lang="scss" scoped>
.checkout-confirmation {
  box-sizing: border-box;
  color: rgb(62, 67, 81);
  font-family: Lato, sans-serif;
  font-size: 14px;
  letter-spacing: 0.56px;
  line-height: 20px;
  margin: 0 auto;
  max-width: 800px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 80px;
  text-align: center;
  width: 800px;

  &__symbol {
    box-sizing: border-box;
    color: $primary;
    display: flex;
    flex-direction: row;
    font-size: 85px;
    justify-content: center;
    letter-spacing: 0.56px;
    line-height: 121.433px;
    text-align: center;
  }

  &__heading {
    box-sizing: border-box;
    color: $black;
    font-size: 30px;
    letter-spacing: 0.56px;
    line-height: 42.85px;
    margin-top: 30px;
    text-align: center;
    -moz-osx-font-smoothing: grayscale;
  }

  &__details {
    box-sizing: border-box;
    color: $black;
    font-size: 20px;
    letter-spacing: 0.56px;
    line-height: 28.5667px;
    margin-top: 30px;
    text-align: center;
    -moz-osx-font-smoothing: grayscale;
  }

  a {
    text-decoration: none;
    color: $primary;
  }
}

@media only screen and (max-width: 800px) {
  .checkout-confirmation {
    width: auto;
  }
}
</style>
